import React, { useState } from 'react'
import styles from './Question.module.css'
import Button from '../Button/index';
import { useDispatch, useSelector } from 'react-redux'
import { getAskMore, getAnswer } from '../../actions/chat';
import { useEffect } from 'react';
import { createNotification } from '../Notifications/Notifications';
import { SET_ASK_MORE } from '../../actions/types';

const Question = () => {
    const dispatch = useDispatch();
    const {answer_id, answer_loading, ask = ''} = useSelector(state => state.gpt);
    const [question, setQuestion] = useState('');
    const [text, setText] = useState('');    
    const [isCheck, setIsCheck] = useState(false);
    const [load, setIsLoad] = useState(false);

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleQuestion();
        }
    }

    const handleQuestion = () => {
        if (question.trim().length > 0) {
            setText('');
            dispatch({type: SET_ASK_MORE, payload: ''});
            dispatch(getAnswer(question));
            setIsCheck(true);
            setIsLoad(true);
        } else {
            createNotification('error', 'Поле с вопроса пусто');
        }
    }

    useEffect(() => {
        let intervalId;
        if (ask?.answer?.trim().length > 0) {
        intervalId = printTextWithDelay(ask?.answer);
        }
        return () => clearInterval(intervalId);
        }, [ask]);
        
        const printTextWithDelay = (text) => {
        let index = -1;
        const id = setInterval(() => {
        if (index < text.length - 1) {
            setText(prev => prev + text[index]);
            index++;
        } else {
        clearInterval(id); // Очистка интервала по завершению печати текста
        }
        }, 70);
        return id; // Возвращаем идентификатор интервала для возможности прерывания
        };

    useEffect(() => {
        let timer = null; 
        if (isCheck) {
        if (ask && ask?.status && ask?.status == 'OK') {
        setIsCheck(false);
        setIsLoad(false);
        } else if (ask && ask?.status && ask?.status == 'ERROR') {
            setIsCheck(false);
            setIsLoad(false);
            createNotification('error', 'Ошибка');
        } else {
        timer = setTimeout(() => {
        answer_id && dispatch(getAskMore(answer_id));
        }, 3000);
        }
        }
        return () => clearTimeout(timer);
        }, [ask, answer_id, isCheck]); 

    return (
    <div className={styles.wrapper_bg}>
        <div className={styles.wrapper}>
        <div className={styles.title}>Задать вопрос</div>

        <div className={styles.listWrapper}>
            <textarea onChange={(e) => setQuestion(e.target.value)} onKeyDown={onKeyDown} value={question} className={styles.textarea} placeholder='Текст вопроса'/>
        </div>
        
            <div className={styles.buttonBLock}>
                <Button disabled={load} onClick={handleQuestion} loading={load}>Задать вопрос</Button>
            </div>
      
        <div className={styles.listWrapper}>
            <textarea className={styles.textarea} style={{height: '320px'}} value={text} disabled placeholder='Текст ответа'/>
        </div>

        </div>
    </div>
  )
}

export default Question