import { createNotification } from "../components/Notifications/Notifications";
import {
    SET_ANSWER_LOADING, 
    SET_ANSWER,
    SET_ASK_MORE_LOADING,
    SET_ASK_MORE
  } from "./types";

export const getAnswer =
(text) => async (dispatch, getState) => {
  dispatch({ type: SET_ANSWER_LOADING, payload: true });
  
    const response = await fetch(`https://gpt.nintegra.ru/api/askmore?background=true`, {
        method: "POST",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },  
        body: JSON.stringify({question: text}),
    });

    const data = await response.json();
    try {
      if (response.status == '200') {
          dispatch({type: SET_ANSWER, payload: data.id});
        }
    } catch (error) {
      createNotification("error", "Ошибка");
    }
  
  dispatch({ type: SET_ANSWER_LOADING, payload: false });
};

export const getAskMore = (id) => async(dispatch, getState) => {
  dispatch({ type: SET_ASK_MORE_LOADING, payload: true });
  const response = await fetch(`https://gpt.nintegra.ru/api/askmore/${id}`);
  const data = await response.json(); 
  dispatch({type: SET_ASK_MORE, payload: data});
  dispatch({ type: SET_ASK_MORE_LOADING, payload: false });
};  