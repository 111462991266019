import storage from 'redux-persist/es/storage';
import {persistCombineReducers} from 'redux-persist';

import gpt from './gpt'

const storageConfig = {
    whitelist: 'auth',
    gpt: 'gpt',
    key: 'certification_storage',
    storage
};

export default persistCombineReducers(storageConfig, {gpt});
