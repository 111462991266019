import Routes from "./routing";
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import reducer from "./reducers";
import {NotificationContainer} from 'react-notifications'
import {PersistGate} from "redux-persist/lib/integration/react";
import {persistStore} from "redux-persist";

import 'react-notifications/lib/notifications.css'

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

const persistor = persistStore(store);

function App() {
  return (
    <div>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <Routes/>
        <NotificationContainer/>
      </Provider>
    </PersistGate>
    </div>
  );
}

export default App;
