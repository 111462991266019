import * as types from "../actions/types";

const initialState = {
    ask: {},
    answer_loading: false,
    answer_id: null,
};

export default function gpt(state = initialState, action) {
    switch (action.type) {
    case types.SET_ASK_MORE:
        return {
            ...state,
            ask: action.payload
        };
    case types.SET_ANSWER_LOADING:
        return {
            ...state,
            answer_loading: action.payload
        };
    case types.SET_ANSWER:
        return {
            ...state,
            answer_id: action.payload
        };
        default:
            return state;
    }
}