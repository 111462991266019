import React from 'react'
import styles from './styles.module.css'
import Question from '../../components/Question/Question'
import background from '../../assets/img/background.mp4'

const Main = () => {
  return (
    <div className={styles.main}>
        <video autoPlay muted loop className={styles.myVideo}>
            <source src={background} type="video/mp4" />
        </video>

        <h1 className={styles.title}>НинтеграGPT</h1>
        <Question/>
    </div>
  )
}

export default Main