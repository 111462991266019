import React from "react";
import {BrowserRouter, Navigate, Route, Routes as Switch} from "react-router-dom";

import {PrivateRoute} from "../components/Routing";

import Layout from "../components/Layout/Layout";
// import Auth from '../pages/Auth'
import Main from "../pages/main/Main";
// import NotFound from "../pages/NotFound/NotFound";


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                {/* <Route path='/auth' element={< Auth />}/> */}
                <Route element={< PrivateRoute />}>
                    <Route path="/" element={< Layout />}>
                        <Route index element={< Main />}/>

                        {/* <Route path="/notFound" element={< NotFound />}/> */}
                    </Route>
                </Route>
                <Route path="*" element={< Navigate to = '/notFound' replace />}/>
            </Switch>
        </BrowserRouter>
    );
}